<template>
	<div>
		<div class="title">
			<img src="../assets/image/registration3.png" alt="">
			<p>请认真填写注册资料，如有问题拨打010-63827176与网站技术联系</p>
		</div>
		<div class="back">
		<!-- 	<div class="tel">
				<img src="../assets/image/typesof.png" alt="" style="width: 20PX;height: 23PX;">
				<p class="telnumber">单位类型</p>
				<p class="asterisk">*</p>
				<el-select placeholder="请选择" v-model="info.companyType">
					<el-option v-for="item in options" :key="item.itemValue" :label="item.itemText"
						:value="item.itemValue">
					</el-option>
				</el-select>
			</div> -->
			<!-- <div class="tel">
				<img src="../assets/image/unit.png" alt="" style="width: 20PX;height: 23PX;">
				<p class="telnumber">单位名称</p>
				<p class="asterisk">*</p>
				<input type="text" placeholder="请输入单位全称" v-model="info.companyName">
			</div>
			<div class="tel" style="padding-top: 27PX;">
				<img src="../assets/image/user.png" alt="" style="width: 20PX;height: 23PX;">
				<p class="telnumber">联系人</p>
				<p class="asterisk">*</p>
				<input type="text" style="margin-left: 18PX;" v-model="info.contacts">
			</div> -->
			<div class="tel" style="padding-top: 27PX;">
				<img src="../assets/image/tel.png" alt="">
				<p class="telnumber">手机号码</p>
				<p class="asterisk">*</p>
				<input type="text" style="margin-left: 2PX;" v-model="info.phone">
				<div class="but" @click="startCountdown" :disabled="isCountingDown">
					{{ buttonText }}
				</div>
			</div>
			<div class="tel" style="padding-top: 27PX;">
				<img src="../assets/image/code.png" alt="" style="width: 20.2PX;height: 23.9PX;">
				<p class="telnumber">短信验证码</p>
				<p class="asterisk" style="margin-right: 40PX;">*</p>
				<input type="text" v-model="info.codeNum">
			</div>
			<div class="tel" style="padding-top: 27PX;">
				<img src="../assets/image/password.png" alt="" style="width: 20PX;height: 23PX;">
				<p class="telnumber">密码登录</p>
				<p class="asterisk">*</p>
				<el-input show-password v-model="info.password"></el-input>
			</div>
			<div class="tel" style="padding-top: 27PX;">
				<img src="../assets/image/confirm.png" alt="" style="width: 23.2PX;height: 23.3PX;">
				<p class="telnumber">确认密码</p>
				<p class="asterisk" style="margin-right: 56PX;">*</p>
				<el-input show-password v-model="info.confirm"></el-input>
			</div>
			<div class="submitto" @click="submitto">
				提交
			</div>
		</div>
		<!-- <div class="support">
			<img :src="this.$store.state.img" alt="">
			<p>微信技术服务</p>
		</div> -->
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				options: [],
				info: {
					companyType: '',
					phone: '',
					password: '',
					confirm: '',
					codeNum:'',
					userType:'user_type_02',
					username:''
				},
				isCountingDown: false,
				remainingSeconds: 0,
				countdownTimer: null,
				isCountingDown2:false
			}
		},
		created() {
			// this.unitType()
		},
		computed: {
			buttonText() {
				if (this.isCountingDown) {
					return `${this.remainingSeconds} 秒后重新获取`;
				} else {
					return '获取验证码';
				}
			},
		},
		methods: {
			startCountdown() {
				if (this.info.phone == '') {
					return this.$message.error('请输入手机号码')
				}
				const regex = /^1[3456789]\d{9}$/
				if (!regex.test(this.info.phone)) {
					return this.$message.error('手机号码格式错误')
				}
				if(this.isCountingDown2){
					return;
				}
				if (this.isCountingDown) {
					return;
				}
				let data = {
					phone:this.info.phone,
					timeOut:60,
					smsmode:1 //注册成功

				}
				this.isCountingDown2 = true
				this.$api.getPhoneCode(data).then(res => {
					this.isCountingDown = true;
					this.remainingSeconds = 60;
			
					this.countdownTimer = setInterval(() => {
						this.remainingSeconds--;
			
						if (this.remainingSeconds <= 0) {
							this.stopCountdown();
						}
					}, 1000);
				}).catch(error => {
					this.isCountingDown2 = false;
					console.error('Failed to request verification code:', error);
				})
			},
			stopCountdown() {
				clearInterval(this.countdownTimer);
				this.isCountingDown = false;
				this.remainingSeconds = 0;
				this.isCountingDown2 = false;
			},
			beforeDestroy() {
				clearInterval(this.countdownTimer);
			},
			unitType() {
				this.$api.unitType().then(res => {
					this.options = res.data.data.result
				})
			},
			submitto() {
				// if (this.info.companyName == '') {
				// 	return this.$message.error('请输入单位名称')
				// }
				// if (this.info.contacts == '') {
				// 	return this.$message.error('请输入联系人')
				// }
				if (this.info.phone == '') {
					return this.$message.error('请输入手机号码')
				}
				const regex = /^1[3456789]\d{9}$/
				if (!regex.test(this.info.phone)) {
					return this.$message.error('手机号码格式错误')
				}
				if(this.info.codeNum == ''){
					return this.$message.error('请输入验证码')
				}
				
				const pas = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*_-]).{8,16}$/
				if (this.info.password == '') {
					return this.$message.error('请输入密码')
				}
				if (!pas.test(this.info.password)) {
					return this.$message.error('密码长度 8-16位字符，需包括字母、数字、特殊符号(特殊符号为!@#$%^&*)')
				}
				if (this.info.confirm == '') {
					return this.$message.error('请输入确认密码')
				}
				if (!pas.test(this.info.confirm)) {
					return this.$message.error('密码长度 8-16位字符，需包括字母、数字、特殊符号(特殊符号为!@#$%^&*)')
				}
				
				if (this.info.password != this.info.confirm) {
					return this.$message.error('确认密码输入错误，不一致')
				}
				this.info.username = this.info.phone + this.info.userType
				
				let encryptedInfo = {
					companyType:this.$encrypt(this.info.companyType),
					phone: this.$encrypt(this.info.phone),
					password: this.$encrypt(this.info.password),
					confirm: this.$encrypt(this.info.confirm),
					codeNum: this.$encrypt(this.info.codeNum),
					username:this.$encrypt(this.info.username),
					userType:this.$encrypt('user_type_02')
				}
				
				this.$api.groupBasics(encryptedInfo).then(res => {
					this.$message.success('注册成功')
					setTimeout(() => {
						this.$router.push({
							path: '/login'
						})
					})
				}).catch(error => {
					console.log('请求失败:', error.message);
				});
			}
		}
	}
</script>

<style  lang="scss" scoped>
	.title {
		width: 1200PX;
		margin: 0 auto;
		display: flex;
		height: 78PX;
		background: #F1F6FF;
		border: 1PX solid #2368E1;
		border-radius: 16PX;
		margin-top: 37PX;
		align-items: center;
	}

	.title img {
		width: 71PX;
		height: 71PX;
		margin-left: 127PX;
	}

	.title p {
		font-size: 18PX;
		font-weight: 400;
		color: #333333;
		margin-left: 74PX;
	}

	.back {
		width: 1200PX;
		margin: 0 auto;
		height: 630PX;
		background: #F8FBFF;
		border-radius: 22PX;
		margin-top: 32PX;
	}

	.back {}

	.tel {
		display: flex;
		align-items: center;
		padding-top: 33PX;
		margin-left: 285PX;
	}

	.tel img {
		width: 17.3PX;
		height: 27.7PX;
	}

	.telnumber {
		font-size: 18PX;
		font-weight: 400;
		color: #333333;
		margin-left: 25PX;
	}

	.tel .asterisk {
		font-size: 25PX;
		font-weight: 400;
		color: #E6101C;
		margin-left: 5PX;
		margin-right: 59PX;
	}

	.tel input {
		width: 312PX;
		height: 43PX;
		background: #FFFFFF;
		border: 1PX solid #164B92;
		border-radius: 7PX;
		padding-left: 10PX;
		font-size: 18PX;
	}

	.tel .el-input {
		width: 312PX;
		height: 43PX;
	}

	.tel ::v-deep .el-input__inner {
		width: 312PX;
		height: 43PX;
		background: #FFFFFF;
		border: 1PX solid #164B92;
		border-radius: 7PX;
		padding-left: 10PX;
		font-size: 18PX;
		color: #000
	}

	.tel ::v-deep .el-input__inner {
		width: 312PX;
		background: #FFFFFF;
		border: 1PX solid #164B92;
		border-radius: 7PX;
		font-size: 18PX;
		color: #000
	}

	.tel ::v-deep input::-webkit-input-placeholder {
		color: #333333;
	}

	.tel ::v-deep input::-moz-input-placeholder {
		color: #333333;
	}

	.tel ::v-deep input::-ms-input-placeholder {
		color: #333333;
	}

	.tel ::v-deep .el-select .el-input .el-select__caret {
		color: #191919;
	}

	.tel .but {
		width: 145PX;
		height: 42PX;
		background: #0063FF;
		border-radius: 7PX;
		font-size: 18PX;
		font-weight: 400;
		color: #FFFFFF;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 22PX;
		cursor: pointer;
	}

	.submitto {
		width: 654PX;
		height: 54PX;
		background-image: linear-gradient(to right, #6FB2FF, #095FFF);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 25PX;
		font-weight: bold;
		color: #FFFFFF;
		margin-left: 288PX;
		margin-top: 43PX;
		cursor: pointer;
	}

	.support {
		width: 1200PX;
		margin: 0 auto;
		height: 223PX;
		background: #F8FBFF;
		border-radius: 22PX;
		display: flex;
		margin-top: 44PX;
		margin-bottom: 170PX;
		align-items: center;
	}

	.support img {
		width: 149PX;
		height: 149PX;
		margin-top: 30PX;
		margin-left: 227PX;
	}

	.support p {
		font-size: 18PX;
		font-weight: 400;
		color: #333333;
		margin-left: 98PX;
	}

	.el-select-dropdown__item {
		font-size: 18PX;
	}
</style>